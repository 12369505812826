import { Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-defect-lookup-window",
  templateUrl: "window.component.html",
})
export class DefectLookupWindowComponent implements OnInit, OnDestroy {
  public _IsLookupClick: boolean;
  public handleDefectLookUpButtonClick: Function;

  public lookupWindow = null;

  ngOnInit() {
    this._IsLookupClick = false;
    this.handleDefectLookUpButtonClick = () => {
      this._IsLookupClick = true;
      this.handleLookUpWindow();
      this.lookupWindow.addEventListener("beforeunload", () => {
        this.closeWindow();
      });
    };
  }

  closeWindow() {
    this._IsLookupClick = false;
    this.lookupWindow.close();
    this.lookupWindow = null;
  }

  handleLookUpWindow() {
    if (!this.lookupWindow && this._IsLookupClick) {
      this.openLookUpWindow();
    } else if (this.lookupWindow && this._IsLookupClick) {
      this.lookupWindow.close();
      this.openLookUpWindow();
    }
  }
  openLookUpWindow() {
    let url = environment.appDomainUrl + "defect-lookup";
    this.lookupWindow = window.open(
      url,
      "",
      "width=813,height=700,top=90,left=120,resizable=yes"
    );
  }

  ngOnDestroy() {
    if (this.lookupWindow) {
      this.lookupWindow.close();
    }
  }
}
