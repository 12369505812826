import { Injectable, EventEmitter } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { authConfig } from "./auth.config";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

export class UserInfo {
  name: string;
  title: string;
  emplid: string;
  department_code: string;
  groups: string[];
}
@Injectable({
  providedIn: "root",
})
export class AuthService {
  /**
   * emits when a new token is received
   */
  onTokenReceived: EventEmitter<any> = new EventEmitter<any>();
  isAuthorized: boolean;
  userProvidedUrl: string;
  isUserProfileLoaded = false;
  validUserAdminGroup: string[];
  userInfo: UserInfo;

  private _user = new BehaviorSubject<string>("Guest");
  private _accessToken = new BehaviorSubject<string>("");
  private isAdmin = new BehaviorSubject<boolean>(false);

  constructor(private oAuthService: OAuthService, private router: Router) {
    this.validUserAdminGroup = environment.authorizedAdminGroup.split(",");
  }

  get username(): Observable<string> {
    return this._user.asObservable();
  }
  get accesstoken(): Observable<string> {
    return this._accessToken.asObservable();
  }
  get isRoleAdmin(): Observable<boolean> {
    return this.isAdmin.asObservable();
  }
  /**
   * configures OAuthService and triggers authentication process
   */
  onInit(): void {
    this.isAuthorized = false;
    if (window.location.pathname == "/etops-significant-review") {
      sessionStorage.setItem("setpage", "true");
    } else if (window.location.pathname != "/auth") {
      sessionStorage.removeItem("setpage");
    }

    this.oAuthService.events.subscribe((event) => {
      if (event.type === "token_received") {
        this.onTokenReceived.emit();
        // pass the access token that was just received to the userInfo endpoint
        // which will return the claims for the authenticated user
        this.oAuthService.loadUserProfile();
      }
      if (event.type === "user_profile_loaded") {
        this.onTokenReceived.emit();
        this.updateUserInfo();
        this.isUserProfileLoaded = true;
      }
    });
    this.oAuthService.configure(authConfig);
    this.oAuthService.customQueryParams = {
      acr_values: "aag:at wia pwd aag:mfagroup:k aag:mfagroup mfa",
    };
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.oAuthService.hasValidAccessToken()) {
        this.oAuthService.initCodeFlow();
      } else {
        // pass the access token that already exists in localstorage to the userInfo endpoint
        // which will return the claims for the authenticated user
        this.oAuthService.loadUserProfile();
      }
    });
    this.oAuthService.setupAutomaticSilentRefresh();
  }
  /**
   * returns the current access_token
   */
  public getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  /**
   * checks if the current access_token is valid
   */
  public hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  public updateUserInfo(): void {
    if (sessionStorage["id_token_claims_obj"] !== undefined) {
      let user = JSON.parse(sessionStorage["id_token_claims_obj"]) as UserInfo;
      this._user.next(user.name);
      this._accessToken.next(this.getAccessToken());

      const validAdminGroup = user.groups.some((item) =>
        this.validUserAdminGroup.some(
          (group) => item.toLowerCase().trim() === group.toLowerCase().trim()
        )
      );

      if (validAdminGroup) {
        this.isAdmin.next(true);
        this.isAuthorized = true;
      } else {
        this.router.navigateByUrl("/notauthorized");
      }
    }
  }
}
