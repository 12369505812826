import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { DeferralCount } from '../models/DeferralCount';
import { DeferralSummary } from '../models/DeferralSummary';
import { PNList } from '../models/PNList';

@Injectable({
  providedIn: 'root'
})
export class LogpageService {

  constructor(private http: HttpClient) { }

  getDeferralCountsByTraxSystem(traxSystem: string) {
    return this.http.get<DeferralCount[]>(`/api/Logpages/TraxSystem/${traxSystem}/DeferralCounts`);
  }

  getDeferralCountsByAircraft(aircraft: string) {
    if (aircraft.length != 3 && aircraft)
      return this.http.get<DeferralCount[]>(`/api/Logpages/Aircraft/${aircraft}/DeferralCounts`);
  }

  getDefectListByTraxSystemAndDeferralCode(traxSystem: string, deferralCode: string) {
    let queryString = `/api/Logpages/TraxSystem/${traxSystem}/DefectList`;
    queryString = deferralCode !== null && deferralCode !== "" ? queryString + `?deferralCode=${deferralCode}` : queryString + '?deferralCode=INCOMPLETE';
    console.debug("calling to api route: " + queryString);
    return this.http.get<DeferralSummary>(`${queryString}`);
  }

  getDefectListByfleettypes(traxtype: string, daysprior: string, chapter: string, fleetcode: string) {
    let queryString = `/api/Logpages/Fleet/TraxSystem/${traxtype}/DaysPrior/${daysprior}/DefectList`;
    if (fleetcode) {
      queryString += "?fleet="+fleetcode;
    }
    if (chapter) {
      queryString += '&chapters=' + chapter;
    }
    console.debug("calling to api route: " + queryString);
    return this.http.get<DeferralSummary>(`${queryString}`);
  }


  getDefectListByAircraftAndDeferralCode(aircraft: string, deferralCode: string) {
    if (aircraft) {
      let queryString = `/api/Logpages/Aircraft/${aircraft}/DefectList`;
      queryString = deferralCode !== null && deferralCode !== "" ? queryString + `?deferralCode=${deferralCode}` : queryString + '?deferralCode=INCOMPLETE';
      console.debug("calling to api route: " + queryString);
      return this.http.get<DeferralSummary>(`${queryString}`);
    }

  }


  getDefectListByAircraftAndDaysPriorByChapters(aircraft: string, daysPrior: string, chapters: string) {
    if (aircraft) {
      let queryString = `/api/Logpages/Aircraft/${aircraft}/DaysPrior/${daysPrior}/DefectList`;
      if (chapters) {
        queryString +='?chapters='+chapters;
      }

      console.debug("calling to api route: " + queryString);
      return this.http.get<DeferralSummary>(`${queryString}`);
    }
  }

  getDefectListByEtopsAircraftsDaysPriorAndChapters(selectedEtopsAircrafts: string, daysPrior: string, chapters: string) {
    if (selectedEtopsAircrafts) {
      let queryString = `/api/Logpages/DefectList?traxSystem=AS&aircrafts=${selectedEtopsAircrafts}&daysprior=${daysPrior}`;
      if (chapters) {
        queryString += `&chapters=${chapters}`;
      }

      console.debug("calling to api route: " + queryString);
      return this.http.get<DeferralSummary>(`${queryString}`);
    }
  }

  getDefectDetailsByLogpageAndAircraft(aircraft: string, logpage: string) {
    if (aircraft) {
      let queryString = `/api/Logpages/Aircraft/${aircraft}/Defect/${logpage}/DefectDetails`;
      console.debug("calling to api route: " + queryString);
      return this.http.get<DeferralSummary>(`${queryString}`);
    }
  }

  getDefectDetailsByLogpageForLookup(traxSystem: string, logpage: string) {
    if (logpage) {
      let queryString = `/api/Logpages/TraxSystem/${traxSystem}/Defect/${logpage}/DefectDetails`;
      console.debug("calling to api route: " + queryString);
      return this.http.get<DeferralSummary>(`${queryString}`);
    }
  }

  getEtopsPNChanges(etopsAircrafts: string, daysprior: string, chapters: string) {
    console.debug("get the  etops aircraft pn changes from the api via aircraft service");
    return this.http.get<PNList>(`/api/Logpages/EtopsPNChanges?Aircraft=${etopsAircrafts}&daysPrior=${daysprior}&chapters=${chapters}`);
  }
}

