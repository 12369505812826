import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { LogpageService } from '../services/logpage.service';
import { LogpageDetail } from '../models/LogpageDetail';
import { INTERNAL_SERVER_ERROR, BAD_REQUEST, NOT_FOUND } from 'http-status-codes';

@Component({
  selector: 'app-defect-details',
  templateUrl: './defect-details.component.html',
  styleUrls: ['./defect-details.component.css']
})


export class DefectDetailsComponent implements OnInit {

  aircraftReg: string;
  logpageDetails: LogpageDetail[];
  deferralView: boolean;

  //on defect details close, highlighting the defect in defect list should be removed
  @Output() closedWindow: EventEmitter<string> = new EventEmitter<string>();

  @Input() set isDeferralCodeClick(value: boolean){
    this.deferralView = value;
  }

  constructor(private lpService: LogpageService) { }

  ngOnInit() {
    console.debug("Entered Defect Details Component");
  }

  getDefectDetails(aircraft:string, logpage:string){
    this.aircraftReg=aircraft;
    this.callService(aircraft,logpage)
  }

  callService(aircraftReg,logpage) {
    console.debug('Calling the logpage details service with: Logpage Number' + logpage + " AC: " + aircraftReg);
    if (logpage && logpage != "" && aircraftReg && aircraftReg != "" && aircraftReg.length == 6) {
      this.lpService.getDefectDetailsByLogpageAndAircraft(aircraftReg, logpage)
        .subscribe((data: any) => this.logpageDetails = data,
          error => this.handleError(error));
    }
    else {
      this.logpageDetails = null;
    }
  }


  closePanel=()=> {
    this.aircraftReg="";
    this.logpageDetails = null;
    this.closedWindow.emit("");
  }

  handleError(error: any): void {
    let httpErrorCode = error.status
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        //this.deferralSummary = DeferralSummary[];
        break;
      case NOT_FOUND:
        //this.deferralSummary = DeferralSummary[];
        break;
      case BAD_REQUEST:
        this.showError(error.message);
        break;
    }
  }
  showError(message: string): void {
    console.debug("Error from the service: " + message);
  }
}
