import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DeferralCount } from '../models/DeferralCount';
import { LogpageService } from '../services/logpage.service';
import { INTERNAL_SERVER_ERROR, BAD_REQUEST, NOT_FOUND } from 'http-status-codes';


@Component({
  selector: 'app-defect-counts',
  templateUrl: './defect-counts.component.html',
  styleUrls: ['./defect-counts.component.css']
})


export class DefectCountsComponent implements OnInit {
  public aircraftReg: string;

  selectedRow: number;
  // functions
  setCountClicked: Function;
  deferralCounts: DeferralCount[];

  // component communications
  @Output() selectedDeferralCode: EventEmitter<string> = new EventEmitter<string>();

  constructor(private lpService: LogpageService) { }

  
  ngOnInit() {
    this.setCountClicked = function (index, deferralCode) {
      console.debug('setting the deferral code:' + deferralCode)
      this.selectedDeferralCode.emit(deferralCode);
      console.debug('Fired event with deferral code:' + deferralCode)
      this.selectedRow = index;
    }
  }
  
  //get counts for text enter based
  Getcounts(value:string)
  {   
    this.clearAC("")
    this.aircraftReg = value;
    console.debug('emitting the BLANK deferral code from DeferralCounts');
    if (this.aircraftReg && this.aircraftReg != undefined && this.aircraftReg.length > 3) {
      this.getDeferralCounts(this.aircraftReg);
    }
    else {
      this.deferralCounts = null;
    }
  }
  setSelectedTraxSystem(traxSystem:string){
    if(traxSystem){
      this.lpService.getDeferralCountsByTraxSystem(traxSystem)
      .subscribe((data: any) => this.deferralCounts = data,
        error => this.handleError(error));
    }
  }

  
  clearAC(e: string) {
    console.debug('Refreshing the AC HIstory component ' + e);
    this.aircraftReg = "";
    this.selectedRow = -1;
    this.deferralCounts = null;
    
  }
  getDeferralCounts(value: string) {   
      
    this.lpService.getDeferralCountsByAircraft(value)
      .subscribe((data: any) => this.deferralCounts = data,
        error => this.handleError(error));
  }

  handleError(error: any): void {
    let httpErrorCode = error.status
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.debug("INTERNAL_SERVER_ERROR"+error.message)
        break;
      case NOT_FOUND:
        console.debug("NOT_FOUND"+error.message)
        break;
      case BAD_REQUEST:
        console.debug("BAD_REQUEST"+error.message)
        break;
    }
  }
  get selectedAC(): string {
    return this.aircraftReg;
  }
}
