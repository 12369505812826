import { Component, Input, Output,OnInit,EventEmitter,ViewChild} from '@angular/core';
import { DeferralSummary } from '../models/DeferralSummary';
import { LogpageService } from '../services/logpage.service';
import { INTERNAL_SERVER_ERROR, BAD_REQUEST, NOT_FOUND } from 'http-status-codes';
import { Deferral } from '../models/Deferral';
import { PNList } from '../models/PNList';
import { PNItem } from '../models/PNItem';
import { DefectDetailsComponent } from '../defect-details/defect-details.component';

@Component({
  selector: 'app-etops-defect-summary',
  templateUrl: './etops-defect-summary.component.html',
  styleUrls: ['./etops-defect-summary.component.css']
})
export class EtopsDefectSummaryComponent implements OnInit {
  private selectedCode: string;

  selectedAC: string;
  selectedDefect:string;
  selectedChapters: string;
  selectedDaysPrior: string;
  selectedEtopsAircrafts:string;
  selectedRow: number;
  deferralSummary: DeferralSummary;
  sortedList: Deferral[];
  actual_sortedList:Deferral[];
  sortedPNList:PNItem[];
  pnList:PNItem[];
  actual_sortedPNList:PNItem[];
  runSearchQuery: Function;
  runSearchQueryByDefCode: Function;
  runSearchQueryByTraxSystem: Function;
  sortDefectAsc: boolean = false;
  sortChapterAsc: boolean = false;
  sortFlightAsc: boolean = false;
  sortDescriptionAsc: boolean = false;
  sortReportAsc: boolean = false;
  sortDateAsc: boolean = false;
  sortAircraftAsc: boolean = false;
  sortPNAsc:boolean=false;
  sortPNDescriptionAsc:boolean=false;
  sortPNInstalledSNAsc:boolean=false;
  traxSystem: string;
  isDefectListPage: boolean = true;
  hasnodata:boolean=false;
  menuItem:string='';

  // component communications
  // @ViewChild('defDetails', { static: false }) defDetails: DefectDetailsComponent;

  private defDetails: DefectDetailsComponent;

  @ViewChild('defDetails', { static: false }) set content(content: DefectDetailsComponent) {
     if(content) { // initially setter gets called with undefined
          this.defDetails = content;
     }
  }

  @Output() verifyAircrafts = new EventEmitter();

  constructor(private lpService: LogpageService) { }
  @Input() set getClickedMenuItem(value: string){
    this.menuItem = value!==undefined?value:'';
  }
  ngOnInit() {
    this.selectedRow = -1;
  }
  resetToDefectListPage=function(){
    this.isDefectListPage=true;
  }
  setReturnToDefectsClicked = function () {
    this.defDetails.closePanel();
    this.selectedCode = "";
    this.isDefectListPage=true;
    this.deferralSummary = null;
    this.selectedRow = -1;
    this.selectedDefect="";
    this.selectedLogpage = "";
    this.selectedDescription = "";
  }
  handlePNReviewClick=function(){
    this.defDetails.closePanel();
    this.hasnodata=false;
    this.selectedDefect="";
    this.isDefectListPage=false;
    this.verifyAircrafts.emit();
    this.initDefect("");
    this.resetSortOptions();
    this.callServiceTogetPNChanges()
  }
  handleReturnToDefectsClick=function(){
    this.defDetails.closePanel();
    this.hasnodata=false;
    this.isDefectListPage=true;
    this.selectedDefect="";
    this.verifyAircrafts.emit();
    this.initDefect("");
    this.resetSortOptions();
    this.callServiceByEtopsAircrafts()
  }

  setDefectClicked=function(index, aircraftreg: string, defectNumber, description){
    if (defectNumber && description && this.selectedDefect!=defectNumber) {
      this.defDetails.closePanel();
      console.debug('setting the selected logpage:' + defectNumber);
      console.debug('setting the selected description:' + description);
      this.selectedRow = index;
      this.selectedDefect=defectNumber;
      this.defDetails.getDefectDetails(aircraftreg.length == 6 ? aircraftreg : "N" + aircraftreg,defectNumber)
    }
  }
  
  runSearchQueryForEtopsAircrafts=function(selectedEtopsAircrafts:string,chapters:string,daysPrior:string){
    this.hasnodata=false;
    this.defDetails.closePanel();
    this.selectedChapters = chapters;
    this.selectedDefect="";
    this.selectedDaysPrior=daysPrior;
    this.selectedEtopsAircrafts=selectedEtopsAircrafts;
    this.selectedDescription = "";
    this.selectedLogpage = "";
    this.selectedRow = -1;
    this.deferralSummary = null;
    this.pnList = null;
    if (this.isDefectListPage) {
      if (selectedEtopsAircrafts) {
        this.callServiceByEtopsAircrafts()
      }
    }else{
      this.callServiceTogetPNChanges()
    }
  }
  //receive close window event
  initDefect(e: string) {
    this.selectedRow = -1;
  }
  resetSortOptions(){
    this.sortAircraftAsc = false;
    this.sortDefectAsc = false;
    this.sortChapterAsc = false;
    this.sortFlightAsc = false;
    this.sortDescriptionAsc = false;
    this.sortPNAsc=false;
    this.sortPNDescriptionAsc=false;
    this.sortPNInstalledSNAsc=false;
  }


  // call the api service to get the summary of log pages by EtopsAircrafts
  callServiceByEtopsAircrafts(){ 
    console.debug('Calling the summary service by Chapters with: Days' + this.selectedDaysPrior + " EtopsAircrafts: " + this.selectedAC + ", Chapters: " + this.selectedChapters);
    this.lpService.getDefectListByEtopsAircraftsDaysPriorAndChapters(this.selectedEtopsAircrafts,this.selectedDaysPrior,this.selectedChapters)
    .subscribe((data: any) => this.handleDeferralData(data),
        error => this.handleError(error));
  }

  // call the api service to get the pn changes of EtopsAircrafts
  callServiceTogetPNChanges(){
    console.debug('Calling the PN Review service by Chapters: '+this.selectedChapters + 'with: Days' +this.selectedDaysPrior);
    this.lpService.getEtopsPNChanges(this.selectedEtopsAircrafts,this.selectedDaysPrior,this.selectedChapters)
    .subscribe((data: any) => this.handlePNData(data),
        error => this.handleError(error));
  }

  onDefectListScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if(this.sortedList.length<this.actual_sortedList.length){
        this.sortedList = [...this.sortedList, ...this.actual_sortedList.slice(this.sortedList.length,this.sortedList.length+100)];
      }
    }
  }
  onPnChangeListScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if(this.sortedPNList.length<this.actual_sortedPNList.length){
        this.sortedPNList = [...this.sortedPNList, ...this.actual_sortedPNList.slice(this.sortedPNList.length,this.sortedPNList.length+100)];
      }
    }
  }


  handlePNData(data: any) {
    this.defDetails.closePanel();
    this.deferralSummary=null;
    this.pnList =null;  /// Existing data clear in grid 
    this.pnList = data;
    this.hasnodata=data.length>0?false:true;
    this.actual_sortedPNList=this.pnList as unknown as PNItem[];
    this.sortedPNList=this.actual_sortedPNList.slice(0,100);
  }

  handleDeferralData(data: any) {
    this.defDetails.closePanel();
    this.pnList=null;
    this.deferralSummary =null;  /// Existing data clear in grid 
    this.deferralSummary = data;
    this.hasnodata=data.deferredItems.length>0?false:true;
    this.actual_sortedList=this.deferralSummary.deferredItems;
    this.sortedList = this.actual_sortedList.slice(0,100);
  }

  get queryByDeferralCode(): string {
    return this.selectedCode;
  }


  handleError(error: any): void {
    let httpErrorCode = error.status
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.debug("INTERNAL_SERVER_ERROR: "+error.message)
        break;
      case NOT_FOUND:
        console.debug("NOT_FOUND: "+error.message)
        break;
      case BAD_REQUEST:
        console.debug("BAD_REQUEST: "+error.message)
        break;
    }
  }

  getSelectedRow(index: number) {
    if (index == this.selectedRow)
      return "Yellow";

    return "White";
  }

  hideCell() {
    if (this.queryByDeferralCode && this.queryByDeferralCode.length > 1) {
      console.debug("attempt to HIDE the cell");
      return true;
    }
    return false;
  }

  displayAircraftReg(ac: string): string {
    if (ac && ac.length == 6) {
      return ac.substring(1, 6);
    }
    return ac;
  }
  getStatusColor(status: string): string {
    if (status == "OPEN") {
      return "Red";
    } else {
      return "";
    }
  }
  ascSort(sortList,sortOperator){
    sortList.sort((obj1, obj2) => {
      if (obj1[sortOperator] > obj2[sortOperator]) {
        return 1;
      }

      if (obj1[sortOperator] < obj2[sortOperator]) {
        return -1;
      }

      return 0;
    });
    return sortList;
  }
  desSort(sortList,sortOperator){
    sortList.sort((obj1, obj2) => {
      if (obj1[sortOperator] < obj2[sortOperator]) {
        return 1;
      }

      if (obj1[sortOperator] > obj2[sortOperator]) {
        return -1;
      }

      return 0;
    });
    return sortList;
  }
  sortByAircraft() {
    let list = this.deferralSummary.deferredItems;
    this.selectedRow = -1;
    if (!this.sortAircraftAsc) {
      this.sortAircraftAsc = true;
      this.actual_sortedList=this.ascSort(list,"aircraftReg")
    }
    else {
      this.sortAircraftAsc = false;
      this.actual_sortedList=this.desSort(list,"aircraftReg")
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }
  sortByDefect() {
    let list = this.deferralSummary.deferredItems;
    this.selectedRow = -1;
    if (!this.sortDefectAsc) {
      this.sortDefectAsc = true;
      this.actual_sortedList=this.ascSort(list,"defectNumber")
    }
    else {
      this.sortDefectAsc = false;
      this.actual_sortedList=this.desSort(list,"defectNumber")
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByChapter() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortChapterAsc) {
      this.sortChapterAsc = true;
      this.actual_sortedList=this.ascSort(list,"chapter")
    }
    else {
      this.sortChapterAsc = false;
      this.actual_sortedList=this.desSort(list,"chapter")
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByFlight() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortFlightAsc) {
      this.sortFlightAsc = true;
      this.actual_sortedList=this.ascSort(list,"flight")
    }
    else {
      this.sortFlightAsc = false;
      this.actual_sortedList=this.desSort(list,"flight")
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByDescription() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortDescriptionAsc) {
      this.sortDescriptionAsc = true;
      this.actual_sortedList=this.ascSort(list,"description")
    }
    else {
      this.sortDescriptionAsc = false;
      this.actual_sortedList=this.desSort(list,"description")
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByDate() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortDateAsc) {
      this.sortDateAsc = true;

      list.sort((obj1, obj2) => {
        return new Date(obj1.reportDate).getTime() - new Date(obj2.reportDate).getTime();
      });
      this.actual_sortedList = list;
    }
    else {
      this.sortDateAsc = false;
      list.sort((obj1, obj2) => {
        return new Date(obj2.reportDate).getTime() - new Date(obj1.reportDate).getTime();
      });
      this.actual_sortedList = list;
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByPNAircraft() {
    let list = this.pnList as unknown as PNItem[];
    this.selectedRow = -1;
    if (!this.sortAircraftAsc) {
      this.sortAircraftAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"ac")
    }
    else {
      this.sortAircraftAsc = false;
      this.actual_sortedPNList=this.desSort(list,"ac")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }

  
  sortByPNDefect() {
    let list = this.pnList as unknown as PNItem[];
    this.selectedRow = -1;
    if (!this.sortDefectAsc) {
      this.sortDefectAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"defect")
    }
    else {
      this.sortDefectAsc = false;
      this.actual_sortedPNList=this.desSort(list,"defect")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }
  
  sortByPNChapter() {
    this.selectedRow = -1;
    let list = this.pnList as unknown as PNItem[];
    if (!this.sortChapterAsc) {
      this.sortChapterAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"chapter")
    }
    else {
      this.sortChapterAsc = false;
      this.actual_sortedPNList=this.desSort(list,"chapter")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }
  sortByPN(){
    let list = this.pnList as unknown as PNItem[];
    this.selectedRow = -1;
    if (!this.sortPNAsc) {
      this.sortPNAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"pnchp")
    }
    else {
      this.sortPNAsc = false;
      this.actual_sortedPNList=this.desSort(list,"pnchp")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }
  sortByPNInstalledSN(){
    let list = this.pnList as unknown as PNItem[];
    this.selectedRow = -1;
    if (!this.sortPNInstalledSNAsc) {
      this.sortPNInstalledSNAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"installedsn")
    }
    else {
      this.sortPNInstalledSNAsc = false;
      this.actual_sortedPNList=this.desSort(list,"installedsn")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }

  sortByPNFlight() {
    this.selectedRow = -1;
    let list = this.pnList as unknown as PNItem[];
    if (!this.sortFlightAsc) {
      this.sortFlightAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"flight")
    }
    else {
      this.sortFlightAsc = false;
      this.actual_sortedPNList=this.desSort(list,"flight")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }

  sortByPNDescription() {
    this.selectedRow = -1;
    let list = this.pnList as unknown as PNItem[];
    if (!this.sortDescriptionAsc) {
      this.sortDescriptionAsc = true;
      this.actual_sortedPNList=this.ascSort(list,"pn_description")
    }
    else {
      this.sortDescriptionAsc = false;
      this.actual_sortedPNList=this.desSort(list,"pn_description")
    }
    this.sortedPNList = this.actual_sortedPNList.slice(0, 100);
  }

}
