import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    // if you are on the auth component
    if (state.url.startsWith('/auth')) {
      // and you have a valid access token
      if (this.authService.hasValidAccessToken()) {
        // send the user to the login page
        this.router.navigateByUrl('');
        return false;
      }
      // and you don't have a valid access token, allow navigation to auth component
      return true;
    } else {
      // you are not on the auth component
      // and you don't have a valid access token
      if (!this.authService.hasValidAccessToken()) {
        // block activation and send to the auth component
        this.router.navigateByUrl('auth');
        return false;
      }
      // you do have a valid access token, allow navigation
      return true;
    }
  }

  canLoad(route: Route): boolean 
  {
    if (this.authService.hasValidAccessToken()) 
    {
        return true; 
    }
    this.router.navigateByUrl('auth');
    return false;		
  }
}
