import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DeferralSummary } from "../models/DeferralSummary";
import { LogpageService } from "../services/logpage.service";
import {
  INTERNAL_SERVER_ERROR,
  BAD_REQUEST,
  NOT_FOUND,
} from "http-status-codes";
import { Deferral } from "../models/Deferral";
import { DefectDetailsComponent } from "../defect-details/defect-details.component";

@Component({
  selector: "app-defect-summary",
  templateUrl: "./defect-summary.component.html",
  styleUrls: ["./defect-summary.component.css"],
})
export class DefectSummaryComponent implements OnInit {
  selectedCode: string;

  selectedAC: string;
  selectedDefect: string;
  selectedACForDetails: string;
  selectedChapters: string;
  selectedDaysPrior: string;
  setReturnToDefectsClicked: Function;
  selectedRow: number;
  deferralSummary: DeferralSummary;
  sortedList: Deferral[];
  actual_sortedList: Deferral[];
  setDefectClicked: Function;
  runSearchQuery: Function;
  runSearchQueryByDefCode: Function;
  runSearchQueryByTraxSystem: Function;
  runSearchQueryFleetype: Function;
  sortDefectAsc: boolean = false;
  sortChapterAsc: boolean = false;
  sortFlightAsc: boolean = false;
  sortDescriptionAsc: boolean = false;
  sortReportAsc: boolean = false;
  sortDateAsc: boolean = false;
  sortAircraftAsc: boolean = true;
  traxSystem: string;

  @ViewChild("defDetails", { static: true }) defDetails: DefectDetailsComponent;

  // component communications
  @Output() public resetSearch: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public isDeferralCodeClick: boolean;

  @Input() set queryByDeferralCode(value: string) {
    console.debug(
      "setting the selected deferral code in defect summary" + value
    );
    this.selectedCode = value;
    this.selectedRow = -1;
    this.deferralSummary = null;
  }

  constructor(private lpService: LogpageService) {}

  ngOnInit() {
    this.selectedRow = -1;
    this.setReturnToDefectsClicked = function () {
      this.selectedCode = "";
      this.deferralSummary = null;
      this.selectedRow = -1;
      this.selectedLogpage = "";
      this.selectedDescription = "";
      this.resetSearch.emit("");
    };

    this.setDefectClicked = function (
      index,
      aircraftreg: string,
      defectNumber,
      description
    ) {
      if (defectNumber && description && this.selectedDefect != defectNumber) {
        this.defDetails.closePanel();
        console.debug("setting the selected logpage:" + defectNumber);
        console.debug("setting the selected description:" + description);
        this.selectedRow = index;
        this.selectedDefect = defectNumber;
        this.defDetails.getDefectDetails(
          aircraftreg.length == 6 ? aircraftreg : "N" + aircraftreg,
          defectNumber
        );
      }
    };

    this.runSearchQueryByDefCode = function (_AC: string, _defCode: string) {
      this.isDeferralCodeClick = true;
      this.defDetails.closePanel();
      this.selectedAC = _AC;
      this.selectedCode = _defCode;
      this.selectedDefect = "";
      this.initDefect("");

      if (
        (this.selectedAC && this.selectedAC.length > 3) ||
        this.selectedAC.length == 6
      ) {
        this.callServiceByDefCode();
      } else {
        console.debug("setting the deferral summary to null");
        this.deferralSummary = null;
        this.queryByDeferralCode = "";
      }
    };
    this.runSearchQueryByTraxSystem = function (
      _TraxSystem: string,
      _DefCode: string
    ) {
      console.debug("Calling the service for " + _TraxSystem);
      this.isDeferralCodeClick = true;
      this.defDetails.closePanel();
      this.traxSystem = _TraxSystem;
      this.selectedAC = "";
      this.selectedDefect = "";
      this.selectedCode = _DefCode;
      this.callServiceByTraxSystem();
    };

    this.runSearchQuery = function (
      _AC: string,
      _chapters: string,
      _daysPrior: string
    ) {
      console.debug("triggered the run search query input.");
      this.isDeferralCodeClick = false;
      this.defDetails.closePanel();
      this.deferralSummary = null;
      this.selectedLogpage = "";
      this.selectedDefect = "";
      this.selectedRow = -1;
      this.initDefect("");
      console.debug(
        "picked up the runSearch event: AC - " +
          _AC +
          " : Chps - " +
          _chapters +
          " : Days - " +
          _daysPrior
      );

      this.selectedAC = _AC;
      this.selectedChapters = _chapters;
      this.selectedDaysPrior = _daysPrior;

      // make sure the aircraft is entered
      if (this.selectedAC) {
        if (_chapters != undefined && _chapters != "" && _chapters) {
          console.debug("searching by chapters");
          this.callServiceByChapters();
        } else {
          this.callService();
        }
      } else if (this.selectedAC == undefined) {
        console.debug("setting the deferral summary to null");
        this.deferralSummary = null;
        this.queryByDeferralCode = "";
        return;
      } else {
        console.debug("setting the deferral summary to null");
        this.deferralSummary = null;
        this.queryByDeferralCode = "";
      }
    };

    //fleet button logics
    this.runSearchQueryFleetype = function (
      traxtype: string,
      daysprior: string,
      chapter: string,
      fleetcode: string,
      fleettype: string
    ) {
      this.defDetails.closePanel();
      this.selectedAC = "";
      this.selectedDefect = "";
      this.selectedCode =
        fleettype == "NG" || fleettype == "MAX" ? fleettype : fleetcode;
      this.isDeferralCodeClick = true;
      this.callServiceByFleetType(
        traxtype,
        daysprior,
        chapter,
        fleetcode === "A320" || "A321" ? fleetcode : fleettype
      );
    };
  }

  clearAC(e: string) {
    console.debug("Refreshing the Defect Summary component " + e);
    this.selectedAC = "";
    this.defDetails.closePanel();
    this.selectedCode = "";
    this.selectedDaysPrior = "";
    this.isDeferralCodeClick = false;
    this.selectedChapters = "";
    this.selectedRow = -1;
    this.deferralSummary = null;
  }
  //scroll logic
  onDefectListScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight &&
      this.sortedList.length < this.actual_sortedList.length
    ) {
      this.sortedList = [
        ...this.sortedList,
        ...this.actual_sortedList.slice(
          this.sortedList.length,
          this.sortedList.length + 100
        ),
      ];
    }
  }
  callService() {
    console.debug(
      "Calling the summary service with: Days" +
        this.selectedDaysPrior +
        " AC: " +
        this.selectedAC
    );
    this.lpService
      .getDefectListByAircraftAndDaysPriorByChapters(
        this.selectedAC,
        this.selectedDaysPrior,
        ""
      )
      .subscribe(
        (data: any) => this.handleData(data),
        (error) => this.handleError(error)
      );
  }

  callServiceByTraxSystem() {
    console.debug(
      "Calling the summary service by Def Code with: Manufacturer" +
        this.traxSystem
    );
    this.lpService
      .getDefectListByTraxSystemAndDeferralCode(
        this.traxSystem,
        this.selectedCode
      )
      .subscribe(
        (data: any) => this.handleData(data),
        (error) => this.handleError(error)
      );
  }

  callServiceByFleetType(
    traxtype: string,
    daysprior: string,
    chapter: string,
    fleetcode: string
  ) {
    console.debug(
      "Calling the summary service by Def Code with: Manufacturer" +
        this.traxSystem
    );
    this.lpService
      .getDefectListByfleettypes(traxtype, daysprior, chapter, fleetcode)
      .subscribe(
        (data: any) => this.handleData(data),
        (error) => this.handleError(error)
      );
  }

  // call the api service to get the summary of log pages by deferral code
  callServiceByDefCode() {
    console.debug(
      "Calling the summary service by Def Code " +
        this.selectedCode +
        " and AC: " +
        this.selectedAC
    );
    this.lpService
      .getDefectListByAircraftAndDeferralCode(
        this.selectedAC,
        this.selectedCode
      )
      .subscribe(
        (data: any) => this.handleData(data),
        (error) => this.handleError(error)
      );
  }

  // call the api service to get the summary of log pages by status (open)
  callServiceByChapters() {
    console.debug(
      "Calling the summary service by Chapters with: Days" +
        this.selectedDaysPrior +
        " AC: " +
        this.selectedAC +
        ", Chapters: " +
        this.selectedChapters
    );
    this.lpService
      .getDefectListByAircraftAndDaysPriorByChapters(
        this.selectedAC,
        this.selectedDaysPrior,
        this.selectedChapters
      )
      .subscribe(
        (data: any) => this.handleData(data),
        (error) => this.handleError(error)
      );
  }

  handleData(data: any) {
    this.deferralSummary = null; /// Existing data clear in grid
    this.deferralSummary = data;
    this.actual_sortedList = this.deferralSummary.deferredItems;
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }
  // receive deferral code from the defect-counts component
  setCode(e: string) {
    console.debug(
      "Settting the selected code in Defect Summary component: " + e
    );
    this.queryByDeferralCode = e;
  }
  //receive close window event
  initDefect(e: string) {
    this.selectedRow = -1;
  }

  get queryByDeferralCode(): string {
    return this.selectedCode;
  }

  handleError(error: any): void {
    let httpErrorCode = error.status;
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.debug("INTERNAL_SERVER_ERROR: " + error.message);
        break;
      case NOT_FOUND:
        console.debug("NOT_FOUND: " + error.message);
        break;
      case BAD_REQUEST:
        console.debug("BAD_REQUEST: " + error.message);
        break;
    }
  }

  getSelectedRow(index: number) {
    if (index == this.selectedRow) return "Yellow";

    return "White";
  }

  hideCell() {
    if (this.queryByDeferralCode && this.queryByDeferralCode.length > 1) {
      console.debug("attempt to HIDE the cell");
      return true;
    }
    return false;
  }

  displayAircraftReg(ac: string): string {
    if (ac && ac.length == 6) {
      return ac.substring(1, 6);
    }
    return ac;
  }
  getStatusColor(status: string): string {
    if (
      this.isDeferralCodeClick &&
      this.selectedCode !== "NG" &&
      this.selectedCode !== "A320" &&
      this.selectedCode !== "NA321"
    ) {
      return "";
    } else if (status == "OPEN") {
      return "Red";
    } else {
      return "";
    }
  }
  ascSort(sortList, sortOperator) {
    sortList.sort((obj1, obj2) => {
      if (obj1[sortOperator] > obj2[sortOperator]) {
        return 1;
      }

      if (obj1[sortOperator] < obj2[sortOperator]) {
        return -1;
      }

      return 0;
    });
    return sortList;
  }
  desSort(sortList, sortOperator) {
    sortList.sort((obj1, obj2) => {
      if (obj1[sortOperator] < obj2[sortOperator]) {
        return 1;
      }

      if (obj1[sortOperator] > obj2[sortOperator]) {
        return -1;
      }

      return 0;
    });
    return sortList;
  }
  sortByAircraft() {
    let list = this.deferralSummary.deferredItems;
    this.selectedRow = -1;
    if (!this.sortAircraftAsc) {
      this.sortAircraftAsc = true;
      this.actual_sortedList = this.ascSort(list, "aircraftReg");
    } else {
      this.sortAircraftAsc = false;
      this.actual_sortedList = this.desSort(list, "aircraftReg");
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }
  sortByDefect() {
    let list = this.deferralSummary.deferredItems;
    this.selectedRow = -1;
    if (!this.sortDefectAsc) {
      this.sortDefectAsc = true;
      this.actual_sortedList = this.ascSort(list, "defectNumber");
    } else {
      this.sortDefectAsc = false;
      this.actual_sortedList = this.desSort(list, "defectNumber");
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByChapter() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortChapterAsc) {
      this.sortChapterAsc = true;
      this.actual_sortedList = this.ascSort(list, "chapter");
    } else {
      this.sortChapterAsc = false;
      this.actual_sortedList = this.desSort(list, "chapter");
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByFlight() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortFlightAsc) {
      this.sortFlightAsc = true;
      this.actual_sortedList = this.ascSort(list, "flight");
    } else {
      this.sortFlightAsc = false;
      this.actual_sortedList = this.desSort(list, "flight");
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByDescription() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortDescriptionAsc) {
      this.sortDescriptionAsc = true;
      this.actual_sortedList = this.ascSort(list, "description");
    } else {
      this.sortDescriptionAsc = false;
      this.actual_sortedList = this.desSort(list, "description");
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }

  sortByDate() {
    this.selectedRow = -1;
    let list = this.deferralSummary.deferredItems;
    if (!this.sortDateAsc) {
      this.sortDateAsc = true;

      list.sort((obj1, obj2) => {
        return (
          new Date(obj1.reportDate).getTime() -
          new Date(obj2.reportDate).getTime()
        );
      });
      this.actual_sortedList = list;
    } else {
      this.sortDateAsc = false;
      list.sort((obj1, obj2) => {
        return (
          new Date(obj2.reportDate).getTime() -
          new Date(obj1.reportDate).getTime()
        );
      });
      this.actual_sortedList = list;
    }
    this.sortedList = this.actual_sortedList.slice(0, 100);
  }
}
