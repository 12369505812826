import {
  Component,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import {
  INTERNAL_SERVER_ERROR,
  BAD_REQUEST,
  NOT_FOUND,
} from "http-status-codes";
import { environment } from "../../environments/environment";
import { Aircraft } from "../models/Aircraft";
import { Aircrafttails } from "../models/Aircrafttails";
import { AircraftService } from "../services/aircraft.service";
import { FormControl } from "@angular/forms";
import { DefectSummaryComponent } from "../defect-summary/defect-summary.component";
import { DefectCountsComponent } from "../defect-counts/defect-counts.component";

@Component({
  selector: "app-achistory",
  templateUrl: "./achistory.component.html",
  styleUrls: ["./achistory.component.css"],
})
export class AchistoryComponent implements OnInit, AfterViewInit {
  @ViewChild("defSumm", { static: true }) defSumm: DefectSummaryComponent;
  @ViewChild("defCount", { static: true }) defCount: DefectCountsComponent;
  @ViewChild("actextbox") actextbox;

  control = new FormControl();

  aircraftReg: string;
  tailnumber: string;
  aircraftRegtailnumber: string;
  traxSystem: string;
  lookupLogpageNumber: string;
  _selectedCode: string;
  _selectAC: string;
  fleettype: string;
  fleettypetrax: string;
  fleetcode: string;

  isValidAC: string;
  bgColor: string;
  clrdropdown: any;

  // button click functions
  setAllClicked: Function;
  setETOPS10Clicked: Function;
  setSVCClicked: Function;
  setENGClicked: Function;
  runSearchByChapter: Function;

  // aircraft service search function
  getAircraftData: Function;
  getAircraftDaysPrior: Function;

  // logpage search - modal lookup
  callSearchForLogpage: Function;

  // input text
  version: string;
  appTitle: string;
  headername: string;
  chapters: string;
  daysPrior: string;
  myColor: string;
  acHistoryUrl;
  dailyPlanurl;
  sigReviewUrl;
  dashboardUrl;
  alaskasworldUrl;
  ac: Aircraft;
  dp: Aircrafttails;
  output: any;
  currentdate: Observable<Date>;
  _currentdate = new BehaviorSubject<Date>(new Date("1970-01-01Z00:00:00:000"));

  @Output() public queryByDeferralCode: string;

  constructor(private aircraftService: AircraftService) {}

  @HostListener("window:beforeunload", ["$event"])
  public ClearAll($event) {
    this.daysPrior = "30";
    this.chapters = "";
    this.defSumm.clearAC("");
  }

  @HostListener("window:pageshow", ["$event"])
  safari(e) {
    if (e.persisted) {
      this.daysPrior = "30";
      this.chapters = "";
      this.defSumm.clearAC("");
      this.clearAC("clear");
    }
  }

  // dropdown selected event
  selectChange(value: any): void {
    // event will give you full breif of action
    this.control.patchValue(value.aircraftRegistration);
    this.aircraftRegtailnumber = "N" + value.aircraftRegistration;
    this.headername = value.manufacturerName;
    this.traxSystem = "";
    this.fleettype = "";
    this.myColor = "white";
    if (this.dp[1]) {
      this.getAircraftData();
      this.dp = this.clrdropdown;
      this.actextbox.nativeElement.blur();
    }
  }

  //input 3 digits Key
  GetDetails(getvalue: any) {
    if (getvalue.keyCode != 38 && getvalue.keyCode != 40) {
      // keypress event
      if (this.control?.value?.length == 3) {
        let data = JSON.parse(sessionStorage.getItem("dataSource"));
        let result = data.filter((product) =>
          product.aircraftTail.includes(getvalue.target.value)
        );
        this.output = result.map((s) => ({
          aircraftRegistration: s.aircraftRegistration.replace("N", ""),
          manufacturerName: s.manufacturerName,
        }));
        this.dp = this.output.sort((a, b) =>
          a.aircraftRegistration < b.aircraftRegistration ? -1 : 1
        );
        if (this.output.length == 0) {
          this.bgColor = "RED";
          this.defSumm.clearAC("");
          this.defCount.clearAC("");
          this.ac = this.clrdropdown;
          this.headername = "";
          this.aircraftRegtailnumber = "";
        } else {
          if (this.output.length == 1) {
            this.bgColor = "WHITE";
            this.dp = this.clrdropdown;
            this.control.patchValue(this.output[0].aircraftRegistration);
            this.aircraftRegtailnumber = "N" + this.control.value;
          }
        }
      } else if (this.control?.value?.length < 3) {
        this.aircraftRegtailnumber = "";
      }

      if (this.control?.value?.length == 4 && getvalue.keyCode != 8) {
        // Enter 4 digits with Duplicate tail number logic
        let results = this.output.filter((product) =>
          product.aircraftRegistration.includes(
            getvalue.target.value.toUpperCase()
          )
        );
        if (this.output.length == 2 && results.length > 0) {
          this.traxSystem = "";
          this.fleettype = "";
          this.bgColor = "WHITE";
          ///let results = this.output.filter((product) => product.aircraftRegistration.includes(getvalue.target.value));
          this.control.patchValue(results[0].aircraftRegistration);
          this.aircraftRegtailnumber = "N" + this.control.value;
          this.dp = this.clrdropdown;
        } else {
          this.bgColor = "RED";
          this.defSumm.clearAC("");
          this.defCount.clearAC("");
          this.ac = this.clrdropdown;
          this.headername = "";
          this.aircraftRegtailnumber = "";
          this.dp = this.clrdropdown;
        }
      }
    }
    if (getvalue.keyCode == 8) {
      // Backspace key
      this.bgColor = "WHITE";
      let str = this.control.value;
      let newstr = str.slice(0, getvalue.target.value.length);
      this.control.patchValue(newstr);
    }
    if (getvalue.keyCode == 13) {
      // Key Enter)
      this.myColor = "white";
      this.traxSystem = "";
      this.fleettype = "";
      if (this.control?.value?.length == 5) {
        this.aircraftRegtailnumber = "N" + this.control.value;
        let data = JSON.parse(sessionStorage.getItem("dataSource"));
        let name = data.filter(
          (product) =>
            product.aircraftRegistration == this.aircraftRegtailnumber
        );
        if (name.length == 1) {
          this.bgColor = "WHITE";
          this.headername = name[0].manufacturerName;
          this.getAircraftData();
          this.dp = this.clrdropdown;
          this.actextbox.nativeElement.blur();
        } else {
          this.bgColor = "RED";
          this.defSumm.clearAC("");
          this.defCount.clearAC("");
          this.ac = this.clrdropdown;
          this.headername = "";
          this.aircraftRegtailnumber = "";
        }
      } else {
        this.bgColor = "RED";
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
        this.aircraftRegtailnumber = "";
      }
    }
  }

  ngOnInit() {
    this.version = "1.5.0";
    this._currentdate.next(new Date());
    this.dashboardUrl = environment.dashboardUrl;
    this.alaskasworldUrl = environment.alaskasworldUrl;
    this.ac = new Aircraft();
    this.isValidAC = "true";
    this.bgColor = "BBFFFF";
    this.ac.lastDaily = "";
    this.ac.lastWeekly = "";
    this.ac.lastCCheck = "";
    this.daysPrior = "30";
    this.ac.IsValidAircraft = "false";
    this.appTitle = environment.appTitle;

    // intial load aricraftregisteration data
    let data = JSON.parse(sessionStorage.getItem("dataSource"));
    if (data == null || data == undefined || data.length == 0) {
      this.getAircraftList();
    }

    // dont' do these search functions if there is a deferral code selected
    this.setAllClicked = function () {
      if (
        (this.aircraftRegtailnumber !== null &&
          this.aircraftRegtailnumber !== "") ||
        (this.fleettype != "" && this.fleettype != null)
      ) {
        this.chapters = "";
        this.daysPrior = "30";
        console.debug("the selected code is....: " + this._selectedCode);
        if (!this._selectedCode || this._selectedCode == "") {
          this.runSearchByChapter();
        }
      } else {
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
      }
    };

    this.setENGClicked = function () {
      if (
        (this.aircraftRegtailnumber !== null &&
          this.aircraftRegtailnumber !== "") ||
        (this.fleettype != "" && this.fleettype != null)
      ) {
        this.chapters = "70,71,72,73,74,75,76,77,78,79,80,49";
        this.daysPrior = "30";
        console.debug("the selected code is....: " + this._selectedCode);
        if (!this._selectedCode || this._selectedCode == "") {
          this.runSearchByChapter();
        }
      } else {
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
      }
    };

    this.setSVCClicked = function () {
      if (
        (this.aircraftRegtailnumber !== null &&
          this.aircraftRegtailnumber !== "") ||
        (this.fleettype != "" && this.fleettype != null)
      ) {
        this.chapters = "12,24,29,32,35,79";
        this.daysPrior = "30";
        console.debug("the selected code is....: " + this._selectedCode);
        if (!this._selectedCode || this._selectedCode == "") {
          this.runSearchByChapter();
        }
      } else {
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
      }
    };

    this.setETOPS10Clicked = function () {
      if (
        (this.aircraftRegtailnumber !== null &&
          this.aircraftRegtailnumber !== "") ||
        (this.fleettype != "" && this.fleettype != null)
      ) {
        this.chapters =
          "21,22,23,24,26,28,29,30,34,36,49,71,72,73,74,75,76,77,79,80";
        this.daysPrior = "10";
        console.debug("the selected code is....: " + this._selectedCode);
        if (!this._selectedCode || this._selectedCode == "") {
          this.runSearchByChapter();
        }
      } else {
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
      }
    };

    this.runSearchByChapter = function () {
      this.getAircraftDaysPrior();
    };

    this.getAircraftDaysPrior = function () {
      if (
        this.aircraftRegtailnumber != "" &&
        this.aircraftRegtailnumber !== undefined
      ) {
        let data = JSON.parse(sessionStorage.getItem("dataSource"));
        let aircraftsData = data.filter(
          (product) =>
            product.aircraftRegistration == this.aircraftRegtailnumber
        );
        this.headername = aircraftsData[0].manufacturerName;
        this.traxSystem = "";
        this.fleettype = "";
        if (this._selectAC == this.aircraftRegtailnumber) {
          this.defSumm.runSearchQuery(
            this.aircraftRegtailnumber,
            this.chapters,
            this.daysPrior
          );
        } else {
          this._selectAC = this.aircraftRegtailnumber;
          this.defCount.Getcounts(this.aircraftRegtailnumber);
          this.defSumm.runSearchQuery(
            this.aircraftRegtailnumber,
            this.chapters,
            this.daysPrior
          );
          this.getAircraftDetails();
        }
      } else if (
        this.fleettypetrax !== null &&
        this.fleettypetrax !== "" &&
        this.fleettypetrax !== undefined
      ) {
        this.fleetclick(this.fleettype, this.fleettypetrax, this.fleetcode);
      }
    };

    this.getAircraftData = function () {
      this._selectAC = this.aircraftRegtailnumber;
      console.debug("Emitting the aircraft search event");
      if (
        this.aircraftRegtailnumber != "" &&
        this.aircraftRegtailnumber !== undefined
      ) {
        this.defCount.Getcounts(this.aircraftRegtailnumber);
        this.defSumm.runSearchQuery(
          this.aircraftRegtailnumber,
          this.chapters,
          this.daysPrior
        );
        this.getAircraftDetails();
      }
    };
  }

  getAircraftDetails() {
    this.initAC();
    this.aircraftService
      .getAircraftDetails(this.aircraftRegtailnumber)
      .subscribe(
        (data: any) => this.handleSuccessdata(data),
        (error) => this.handleError(error)
      );
  }

  // local storage data load function
  getAircraftList() {
    this.aircraftService.getAircraftList().subscribe(
      (data: any) => this.handleSuccess(data),
      (error) => this.handleError(error)
    );
  }

  ngAfterViewInit() {
    this.currentdate = this._currentdate.asObservable();
  }
  handleSuccess(data: any) {
    console.debug(
      "Successfully called the aircraft service.  Setting the background color to white"
    );
    sessionStorage.setItem("dataSource", JSON.stringify(data));
    this.bgColor = "WHITE";
  }
  handleSuccessdata(data: any) {
    console.debug(
      "Successfully called the aircraft service.  Setting the background color to white"
    );
    this.ac = data;
    this.bgColor = "WHITE";
  }

  handleError(error: any): void {
    let httpErrorCode = error.status;
    console.debug("Aircraft service failed with :");
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.debug("Internal server error");
        this.bgColor = "RED";
        this.initAC();
        break;
      case NOT_FOUND:
        console.debug("Not found error");
        this.bgColor = "RED";
        this.initAC();
        break;
      case BAD_REQUEST:
        console.debug("Bad Request error");
        break;
      default:
        this.bgColor = "RED";
        this.initAC();
        break;
    }
  }

  initAC() {
    this.ac = new Aircraft();
    this.ac.IsValidAircraft = "false";
    this.ac.lastCCheck = "";
    this.ac.lastDaily = "";
    this.ac.lastWeekly = "";
    this.ac.totalCycles = "";
    this.ac.totalHours = "";
  }

  // Refresh Button
  Refresh(values: string) {
    if (this.aircraftRegtailnumber != undefined) {
      this.clearAC(values);
    }
  }

  // receive refreshPage event from the menu list when the ac history link is clicked
  clearAC(e: string) {
    if (e == "reload") {
      console.debug(
        "Reloading the AC History component with current entries -" + e
      );
      this._selectAC = this.aircraftRegtailnumber;
      if (this.aircraftRegtailnumber.trim() != "") {
        this.myColor = "white";
        this.traxSystem = "";
        this.fleettype = "";
        let data = JSON.parse(sessionStorage.getItem("dataSource"));
        let aircraftsData = data.filter(
          (product) =>
            product.aircraftRegistration == this.aircraftRegtailnumber
        );
        this.headername = aircraftsData[0].manufacturerName;
        this.getAircraftList();
        this.getAircraftData();
      } else if (this.fleettype != "" && this.fleettype != undefined) {
        console.debug("call fleet Reload");
        if (Number(this.daysPrior) > 120) {
          this.myColor = "red";
          this.defSumm.clearAC("");
        } else {
          this.myColor = "white";
          this.fleetclick(this.fleettype, this.fleettypetrax, this.fleetcode);
        }
      } else {
        this.defSumm.clearAC("");
        this.defCount.clearAC("");
        this.ac = this.clrdropdown;
        this.headername = "";
      }
    }
    if (e == "clear") {
      console.debug("Refreshing the AC History component -" + e);
      this.aircraftRegtailnumber = "";
      this._selectAC = "";
      this.initAC();
      this.chapters = "";
      this.daysPrior = "30";
      this.defSumm.clearAC("");
      this.defCount.clearAC("");
      this.dp = this.clrdropdown;
      this.control.patchValue("");
      this.actextbox.nativeElement.focus();
      this.bgColor = "WHITE";
      this.headername = "";
      this.traxSystem = "";
      this.fleettype = "";
      this.myColor = "white";
    }
  }

  // receive deferral code from the defect-counts component
  setCode(e: string) {
    console.debug("Settting the selected code in AC History component:" + e);
    this._selectedCode = e;
    this.queryByDeferralCode = e;
    if (
      this.traxSystem !== null &&
      this.traxSystem !== "" &&
      this.traxSystem !== undefined
    ) {
      this.defSumm.runSearchQueryByTraxSystem(
        this.traxSystem,
        this.queryByDeferralCode
      );
    } else {
      this.defSumm.runSearchQueryByDefCode(
        this._selectAC,
        this.queryByDeferralCode
      );
    }
    this._selectedCode = "";
  }

  resetSearchString(value: string) {
    this._selectedCode = "";
    this.defSumm.runSearchQuery(
      this.aircraftRegtailnumber,
      this.chapters,
      this.daysPrior
    );
  }

  handleDMIButtonClick(event, traxSystem) {
    this.fleettype = "";
    this.myColor = "white";
    this.traxSystem = traxSystem;
    this.headername = traxSystem == "VX" ? "Airbus" : "Boeing";
    this.ac = new Aircraft();
    this.initAC();
    this.aircraftRegtailnumber = "";
    this.bgColor = "WHITE";
    this.control.patchValue("");
    this.defCount.clearAC(event);
    this.defSumm.clearAC(event);
    this.chapters = "";
    this.daysPrior = "30";
    this.defCount.setSelectedTraxSystem(traxSystem);
  }

  searchByDays(event) {
    console.debug("called the searchByDays event" + this.aircraftRegtailnumber);
    if (
      this.aircraftRegtailnumber !== null &&
      this.aircraftRegtailnumber !== ""
    ) {
      console.debug("setting selected days prior to " + this.daysPrior);
      this.myColor = "white";
      this.getAircraftDaysPrior();
    } else if (this.fleettype != "" && this.fleettype != undefined) {
      console.debug("call fleet type based");
      if (Number(this.daysPrior) > 120) {
        this.myColor = "red";
        this.defSumm.clearAC(event);
      } else {
        this.myColor = "white";
        this.fleetclick(this.fleettype, this.fleettypetrax, this.fleetcode);
      }
    } else {
      this.defSumm.clearAC("");
      this.defCount.clearAC("");
      this.ac = this.clrdropdown;
      this.headername = "";
    }
    let target = event.target;
    target.blur();
  }

  getChaptersEntered(event) {
    console.debug("called the getChaptersEntered event");
    if (
      this.aircraftRegtailnumber !== null &&
      this.aircraftRegtailnumber !== ""
    ) {
      this.queryByDeferralCode = "";
      console.debug("setting selected chapters to " + this.chapters);
      this._selectedCode = "";
      this.runSearchByChapter();
    } else if (this.fleettype != "" && this.fleettype != undefined) {
      console.debug("call fleet type based");
      if (Number(this.daysPrior) > 120) {
        this.defSumm.clearAC(event);
        this.myColor = "red";
      } else {
        this.myColor = "white";
        this.fleetclick(this.fleettype, this.fleettypetrax, this.fleetcode);
      }
    } else {
      this.defSumm.clearAC("");
      this.defCount.clearAC("");
      this.ac = this.clrdropdown;
      this.headername = "";
    }
    let target = event.target;
    target.blur();
  }

  fleetclick(fleetType, traxtype, fleetcode) {
    this.myColor = "white";
    this.fleettype = fleetType;
    this.fleettypetrax = traxtype;
    this.fleetcode = fleetcode;
    this.traxSystem = "";
    this.defSumm.clearAC("");
    this.defCount.clearAC("");
    this.initAC();
    this.dp = this.clrdropdown;
    this.control.patchValue("");
    this.bgColor = "WHITE";
    this.aircraftRegtailnumber = "";
    this.headername = traxtype == "VX" ? "Airbus" : "Boeing";
    // Fleet based Service call
    if (Number(this.daysPrior) > 120) {
      this.myColor = "red";
    } else {
      this.defSumm.runSearchQueryFleetype(
        traxtype,
        this.daysPrior,
        this.chapters,
        fleetcode,
        fleetType
      );
    }
  }
}
