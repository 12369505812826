import { Component, OnInit,Output,ViewChild } from '@angular/core';
import { INTERNAL_SERVER_ERROR, BAD_REQUEST, NOT_FOUND } from 'http-status-codes';
import { environment } from '../../environments/environment';
import {EtopsAircraft} from '../models/EtopsAircraft';
import { AircraftService } from '../services/aircraft.service';
import { EtopsDefectSummaryComponent } from '../etops-defect-summary/etops-defect-summary.component';

@Component({
  selector: 'app-etops-significant-review',
  templateUrl: './etops-significant-review.component.html',
  styleUrls: ['./etops-significant-review.component.css']
})
export class EtopsSignificantReviewComponent implements OnInit {

  constructor(private aircraftService: AircraftService) { }

  //View Childs
  @ViewChild('etopsDefSumm',{ static: false }) etopsDefSumm: EtopsDefectSummaryComponent;

  //outputs
  @Output() public getClickedMenuItem:string='CURRENT MISSION';

  //variables
  chapters: string;
  daysPrior: string;
  etopsAircrafts: EtopsAircraft[];
  dashboardUrl: string;
  alaskasworldUrl:string;
  menuItemName:string="currentMission";

  handleAction:Function;

  //handle chapter and dayprior Change
  handleChapterChange:Function;
  handleDaysPriorChange:Function;

  ngOnInit() {
    this.dashboardUrl = environment.dashboardUrl;
    this.alaskasworldUrl=environment.alaskasworldUrl;
    this.chapters="21,22,23,24,26,28,29,30,34,36,49,71,72,73,74,75,76,77,79,80";
    this.daysPrior="10";
    this.handleAction=(type)=>{
      switch(type) {
        case "selectAll":
          this.etopsAircrafts.forEach((item)=>item.selected=true);
          this.getClickedMenuItem="ALL AC";
          this.menuItemName=type;
          break;
        case "clearAll":
          this.etopsAircrafts.forEach((item) => item.selected = false);
          this.getClickedMenuItem="";
          this.menuItemName=type;
          break;
        case "currentMission":
          this.etopsAircrafts.forEach((item)=>{item.selected=(item.etopsmission === 'YES')});
          this.getClickedMenuItem="CURRENT MISSION";
          this.menuItemName=type;
          break;
        case "nonCurrentMission":
          this.etopsAircrafts.forEach((item) => { item.selected = (item.etopsmission !== 'YES') });
          this.getClickedMenuItem="NON CURRENT MISSION";
          this.menuItemName=type;
          break;
        case "800s":
          this.etopsAircrafts.forEach((item)=>{item.selected=(item.aC_SERIES.includes('800'))});
          this.getClickedMenuItem="800's";
          this.menuItemName=type;
          break;
        case "900s":
          this.etopsAircrafts.forEach((item)=>{item.selected=(item.aC_SERIES.includes('900'))});
          this.getClickedMenuItem="900's";
          this.menuItemName=type;
          break;
        case "MAXs":
          this.etopsAircrafts.forEach((item) => { item.selected = (item.aC_SERIES.includes('M9')) });
          this.getClickedMenuItem="MAX's";
          this.menuItemName=type;
          break;
        case "2dayAll":
          this.etopsAircrafts.forEach((item)=>item.selected=true);
          this.chapters = "";
          this.daysPrior = "2";
          this.getClickedMenuItem="2-DAY ALL";
          this.menuItemName=type;
          break;
        case "chapterAll":
          this.chapters = "";
          //this is to display respective menu text on header
          this.verifyAircrafts()
          break;
        case "chapterETOPS10":
          this.chapters = "21,22,23,24,26,28,29,30,34,36,49,71,72,73,74,75,76,77,79,80";
          //this is to display respective menu text on header
          this.verifyAircrafts()
          break;
        case "chapterSVC":
          this.chapters = "12,24,29,32,35,79";
          //this is to display respective menu text on header
          this.verifyAircrafts()
          break;
        case "chapterENG":
          this.chapters = "70,71,72,73,74,75,76,77,78,79,80,49";
          //this is to display respective menu text on header
          this.verifyAircrafts()
          break;
        default:
          this.etopsAircrafts.forEach((item)=>{item.selected=(item.etopsmission === 'YES')})
          break;
      }
      this.getDefectList();
    }

    //START==> chapter and dayprior Change functions
    this.handleChapterChange = function (e) {
      this.chapters=e.target.value;
      //this is to display respective menu text on header
      this.verifyAircrafts()
      this.getDefectList();
      e.target.blur();
    };
    this.handleDaysPriorChange = function (e) {
      this.daysPrior=e.target.value;
      //this is to display respective menu text on header
      this.verifyAircrafts()
      this.getDefectList();
      e.target.blur();
    };
    //STOP==> chapter and dayprior Change functions
  }
  ngAfterContentInit() {
    //Api Call to get EtopsAircrafts
    this.getAircrafts();
  }
  getAircrafts(){
    let data = JSON.parse(sessionStorage.getItem("etopsAircrafts"));
    if (data == null || data == undefined || data.length == 0) {
      this.getEtopsAircrafts();
    }else{
      data.map((item) => { item.selected = (item.etopsmission === 'YES') })
      this.etopsAircrafts = data;
      this.getDefectList();
    }
  }
  getEtopsAircrafts(){
    this.aircraftService.getEtopsAircraft().subscribe(
      (data: any) => this.handleSuccess(data),
      error => this.handleError(error)
    );
  }

  getDefectList(){
    let aircrafts=[];
    this.etopsAircrafts.forEach((item)=>{
      if(item.selected){
        aircrafts.push(item.ac)
      }
    })
    if(this.etopsDefSumm){
      this.etopsDefSumm.runSearchQueryForEtopsAircrafts(aircrafts.toString(), this.chapters, this.daysPrior);
    }else{
      setTimeout(() => { 
        this.etopsDefSumm.runSearchQueryForEtopsAircrafts(aircrafts.toString(), this.chapters, this.daysPrior)
      }, 200);
    }
  }
  verifyAircrafts(){
    const checketopsAircrafts=this.etopsAircrafts.map(a => {return {...a}})
    checketopsAircrafts.forEach((item) => item.selected = true);
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0 && this.daysPrior=='2'&& this.chapters==''){
      this.getClickedMenuItem = "2-DAY ALL";
      return;
    }
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem = "ALL AC";
      return;
    }
    checketopsAircrafts.forEach((item) => { item.selected = (item.etopsmission === 'YES') });
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem = "CURRENT MISSION";
      return;
    }
    checketopsAircrafts.forEach((item) => { item.selected = (item.etopsmission !== 'YES') });
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem ="NON CURRENT MISSION";
      return;
    }
    checketopsAircrafts.forEach((item) => { item.selected = (item.aC_SERIES.includes('800')) });
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem ="800's";
      return;
    }
    checketopsAircrafts.forEach((item) => { item.selected = (item.aC_SERIES.includes('900')) });
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem ="900's";
      return;
    }
    checketopsAircrafts.forEach((item) => { item.selected = (item.aC_SERIES.includes('M9')) });
    if(checketopsAircrafts.filter((a) => this.etopsAircrafts.some((b) =>a.ac==b.ac&&a.selected !== b.selected)).length===0){
      this.getClickedMenuItem = "MAX's";
      return;
    }

    this.getClickedMenuItem = "";
  }

  refreshEtopsSignificant(e: string) {
    if(e == 'reload'){
      //this is to display respective menu text on header
      this.verifyAircrafts()
      this.getDefectList();
    }
    else if(e == 'clear'){
      this.etopsAircrafts=[];
      this.dashboardUrl = environment.dashboardUrl;
      this.alaskasworldUrl = environment.alaskasworldUrl;
      this.chapters = "21,22,23,24,26,28,29,30,34,36,49,71,72,73,74,75,76,77,79,80";
      this.daysPrior = "10";
      this.getClickedMenuItem="CURRENT MISSION";
      this.menuItemName="currentMission";
      this.etopsDefSumm.resetToDefectListPage();
      this.getAircrafts()
    }
  }

  //handle etops aircraft list success
  handleSuccess(data: any) {
    console.debug("Successfully called the aircraft service and returned the list of ETOPS Aircraft for VX.");
    sessionStorage.setItem("etopsAircrafts",JSON.stringify(data));
    data.map((item)=>{item.selected=(item.etopsmission === 'YES')})
    this.etopsAircrafts = data;
    this.getDefectList();
  }

  //handle etops aircraft list error
  handleError(error: any): void {
    let httpErrorCode = error.status
    console.debug("Aircraft service failed with :");
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.error("Internal server error");
        break;
      case NOT_FOUND:
        console.error("Not found error");
        break;
      case BAD_REQUEST:
        break;
      default :
        break;
    }
  }
}
