import { Component, OnInit, Output, EventEmitter,ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { DefectLookupWindowComponent } from '../defect-lookup/window.component';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.css']
})


export class MenuListComponent implements OnInit {
  // component communications
  @Output() refreshPage: EventEmitter<string> = new EventEmitter<string>();
  @Output() reloadEtopsSignificantReviewPage: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('defLookUpWindow', { static: true }) defLookUpWindow: DefectLookupWindowComponent;


  dailyPlanurl: string;
  otsUrl:string;  

  ngOnInit() {
    this.dailyPlanurl = environment.dailyPlanurl;
    this.otsUrl=environment.otsUrl;
  }

  refreshPages(e) {
    console.debug("Emitting clear AC event");
    this.refreshPage.emit(e);
  }

  // for opening a modal popup - defect look up
  ViewDefectLookup() {
    this.defLookUpWindow.handleDefectLookUpButtonClick();
  }

}
