// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  qa: false,
  test: true,
  clientId: "1rJJgsHwC1nM18Pwf",
  appDomainUrl: "https://aircraftmtxhistory-test.alaskaair.com/",
  provider: "https://fedauth.qa.alaskasworld.com",
  dashboardUrl:
    "https://alaskaair.sharepoint.com/sites/maintenanceaa/Pages/Dashboards.aspx",
  alaskasworldUrl: "https://www.alaskasworld.com",
  apiBaseUrl: "https://aircraftmtxhistoryapi.test.webservice.alaskaair.com",
  dailyPlanurl: "https://linemtxworkload.test.alaskaair.com/",
  appTitle: "AIRCRAFT HISTORY",
  otsUrl: "https://outofservice.test.alaskaair.com",
  authorizedAdminGroup:
    "AS M&E Non-union,AS M&E Technicians,AS Maintenance Control Dept,ics_vx2mande,AS_ACHistoryDashboard_AdHoc",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 */
