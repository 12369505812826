import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'AircraftMtxHistory';

    constructor(private authService: AuthService) 
    {
    }
    ngOnInit() 
    {
        this.authService.onInit();
    }
    ngOnDestroy:()=>{}
    
    public get allowAccess() {
      return this.authService.hasValidAccessToken();
    }

}

