import {Component, OnInit,  OnDestroy} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  onTokenReceivedSubscription: Subscription;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.onTokenReceivedSubscription = this.authService.onTokenReceived.subscribe(() => {
      if (this.authService.hasValidAccessToken()) {
        if(sessionStorage.getItem('setpage') =='true')
       {    
           this.router.navigateByUrl('/etops-significant-review'); 
       } 
       else
       {        
            this.router.navigateByUrl(''); 
       } 
      }
    });
  }

  ngOnDestroy() {
    this.onTokenReceivedSubscription.unsubscribe();
  }
}
