import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AngularMaterialModule } from "./angular-material.module";
import { AppComponent } from "./app.component";
import { AchistoryComponent } from "./achistory/achistory.component";
import { EtopsSignificantReviewComponent } from "./etops-significant-review/etops-significant-review.component";
import { MenuListComponent } from "./menu-list/menu-list.component";
import { MenuListHistoryComponent } from "./menu-list/menu-listhistory.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AircraftService } from "./services/aircraft.service";
import { DefectCountsComponent } from "./defect-counts/defect-counts.component";
import { LogpageService } from "./services/logpage.service";
import { DefectSummaryComponent } from "./defect-summary/defect-summary.component";
import { EtopsDefectSummaryComponent } from "./etops-defect-summary/etops-defect-summary.component";
import { DefectDetailsComponent } from "./defect-details/defect-details.component";
import { DefectLookupWindowComponent } from "./defect-lookup/window.component";
import { DefectLookupComponent } from "./defect-lookup/defect-lookup.component";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { AuthComponent } from "./auth/auth.component";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { AuthService } from "./auth/auth.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingInterceptor } from "./interceptors/loading-interceptor";
import { PortalModule } from "@angular/cdk/portal";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoaderComponent } from "./loading/loader.component";
import { LoaderService } from "./services/loader.service";
import { NotAuthorizedPageComponent } from "./not-authorized-page/not-authorized-page.component";

const routes: Routes = [
  {
    // componentless and pathless route with AuthGuard acts as universal canActivate guard
    // do not remove unless you have a better way to implement a universal guard
    path: "",
    // canActivate: [AuthGuard, AuthorizeAdminGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: AchistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "auth",
        component: AuthComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "achistory",
        component: AchistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "defect-counts",
        component: DefectCountsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "defect-details",
        component: DefectDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "defect-lookup",
        component: DefectLookupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "defect-summary",
        component: DefectSummaryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "etops-defect-summary",
        component: EtopsDefectSummaryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "etops-significant-review",
        component: EtopsSignificantReviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "etops-significant-review/defect-lookup",
        component: DefectLookupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "notauthorized",
        component: NotAuthorizedPageComponent,
      },
      {
        path: "app-loader",
        component: LoaderComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
@NgModule({
  declarations: [
    AppComponent,
    DefectCountsComponent,
    AchistoryComponent,
    EtopsSignificantReviewComponent,
    MenuListComponent,
    MenuListHistoryComponent,
    DefectSummaryComponent,
    EtopsDefectSummaryComponent,
    DefectDetailsComponent,
    DefectLookupWindowComponent,
    DefectLookupComponent,
    AuthComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularMaterialModule,
    HttpClientModule,
    FormsModule,
    OAuthModule.forRoot(),
    RouterModule.forRoot(routes),
    PortalModule,
    MatTooltipModule,
  ],
  providers: [
    AircraftService,
    LogpageService,
    AuthService,
    LoaderService,
    { provide: OAuthStorage, useValue: sessionStorage },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
