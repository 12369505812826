import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { LogpageService } from '../services/logpage.service';
import { LogpageDetail } from '../models/LogpageDetail';
import { INTERNAL_SERVER_ERROR, BAD_REQUEST, NOT_FOUND } from 'http-status-codes';
import { MatSort } from "@angular/material/sort";
import { MatDialogRef } from "@angular/material/dialog";
const WHITE = "WHITE";

@Component({
  selector: 'app-defect-lookup',
  templateUrl: './defect-lookup.component.html',
  styleUrls: ['./defect-lookup.component.css']
})
export class DefectLookupComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  lookupLogpageNumber: string;
  traxSystem:string;
  errorMessage:string;
  bgColor: string;
  itemBgColor: string;
  logpageDetails: LogpageDetail[];
  dialogRef: MatDialogRef<DefectLookupComponent>;
  @ViewChild("defectNumberBox") defectNumBox: ElementRef;

  constructor(private lpService: LogpageService) { }

  ngOnInit() {
    this.traxSystem = "AS";
    this.errorMessage = "";
    this.bgColor = WHITE;
    this.itemBgColor = WHITE;
  }
  callSearchForLogpage = function (trax:string) {
    this.logpageDetails = null;
    this.bgColor = WHITE;
    this.itemBgColor = WHITE;
    if (this.lookupLogpageNumber) {
      this.lookupLogpageNumber = this.lookupLogpageNumber.trim();
      this.logpageDetails = null;
      this.lpService.getDefectDetailsByLogpageForLookup(trax, this.lookupLogpageNumber).subscribe(
        (data: any) => this.handleSearchSuccess(data),
        error => this.handleSearchError(error)
      );
    }
  };

  handleSearchError(error: any): void {    
    this.defectNumBox.nativeElement.focus();
    let httpErrorCode = error.status
    console.error("Logpage Search service failed with :");
    switch (httpErrorCode) {
      case INTERNAL_SERVER_ERROR:
        console.error("Internal server error");
        this.errorMessage = "Internal server error";
        break;
      case NOT_FOUND:
        console.error("Not found error");
        this.errorMessage = "Not found error";
        break;
      case BAD_REQUEST:
        this.errorMessage = "Bad request error";
        break;
      default:
        this.errorMessage = "Error";
        break;
    }
  }
  
  displayAircraft(reg: string){
    if (reg.length==6)
      return reg.substring(1,6);
    return reg;
  }
  searchLogpageDetails(trax: string) {
    if(trax)
      this.traxSystem = trax;
    console.debug('called the getLogpageDetails event: lopgage=' + this.lookupLogpageNumber + "; traxSystem=" + this.traxSystem);
    if (this.lookupLogpageNumber) {
      console.debug('calling the logpage search API')
      this.callSearchForLogpage(this.traxSystem);
    }    
  }
  handleSearchSuccess(data: any) {
    console.debug("Successfully called the logpage service.  Setting the logpage details");
    this.logpageDetails = data;
    console.debug("logpage details:  Count:" + this.logpageDetails.length);
    if(this.logpageDetails.length)
    {
      this.errorMessage = "";
    }
    else
    {
      this.errorMessage = "Defect " + this.lookupLogpageNumber + " is not found in " + (this.traxSystem == 'VX' ? 'Airbus': 'Boeing') + " Trax";      
      this.defectNumBox.nativeElement.focus();
    }
  }
  clearDefect() {
    this.itemBgColor = WHITE;
    this.bgColor = WHITE;
    this.lookupLogpageNumber = "";
    this.logpageDetails = null;
    this.traxSystem = 'AS';
    this.errorMessage = "";
    this.defectNumBox.nativeElement.focus();
  }
}
