import { Injectable } from '@angular/core';
import { Aircrafttails } from '../models/Aircrafttails';
import { Aircraft } from '../models/Aircraft';
import { EtopsAircraft } from '../models/EtopsAircraft';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AircraftService {
  list: EtopsAircraft[];

  constructor(private http: HttpClient) {
  }

  getAircraftList() {
    console.debug(`calling to api route: /api/Airlines/AS/Aircraft`);
    return this.http.get<Aircrafttails>(`/api/Airlines/AS/Aircraft`);
  }
  getAircraftDetails(aircraft: string) {
    console.debug(`calling to api route: /api/Aircraft/N${aircraft}VA`);
    return this.http.get<Aircraft>(`/api/Aircraft/${aircraft}`);
  }
  getEtopsAircraft() {
    let airline = 'AS'
    console.debug("get the  etops aircraft from the api via aircraft service");
    return this.http.get<EtopsAircraft[]>(`/api/Airlines/${airline}/Aircraft/EtopsMission`);
  }

}
