import { IDeserializable } from './IDeserializable';


export class Aircraft implements IDeserializable {

  aircraftRegistration: string;
  totalHours: string;
  totalCycles: string;
  lastDaily: string;
  lastWeekly: string;
  lastCCheck: string;
  lastACheck: string;
  IsValidAircraft: string; 
  lastCAT3:string[];
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

