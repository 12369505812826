import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { finalize,tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../services/loader.service';

declare function InitLoader(): any;
declare function StartLoader(): any;
declare function StopLoader(): any;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    constructor(private authService: AuthService,public loaderService: LoaderService) {
        console.debug("loader init");
        InitLoader();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if(request.url.startsWith("/api") )
        {
            if(this.totalRequests++ === 0)
            {
                this.loaderService.show();
                console.debug("start loader " + request.urlWithParams);
                StartLoader(); 
            }
            const requestNoCache = request.clone({
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.authService.getAccessToken()}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '-1'
                })
              });
              return this.handler(next, requestNoCache);
        }
        else
            return this.handler(next, request);
    }

    handler(next, request) { return next.handle(request)
        .pipe(
            tap(
                (event) => {
                    if(event instanceof HttpResponse) {
                        if(request.url.startsWith("/api") && --this.totalRequests === 0){
                            console.debug("stop loader " + request.urlWithParams);
                            StopLoader();
                            this.loaderService.hide()
                        }                        
                    }
                },
                (error: HttpErrorResponse) => {
                    this.totalRequests = 0;
                    console.debug("stop loader due to error " + request.urlWithParams);
                    StopLoader();
                    this.loaderService.hide()
                    throw error;
                }
            ),
            finalize(()=>{
                StopLoader();
                this.loaderService.hide()
            })
        );
    }
}   